import React from 'react'
import { Link } from 'react-router-dom'
import BannerImage from '../../sub-components/banner-image'

const styles = {
    container: {
      lineHeight: 1.6,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  };

const Privacy = () => {
    return (
        <div className='bg-[#ffff]'>
            <BannerImage />
            <section className='relative mx-5 mt-5 xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)] overflow-hidden pb-10'>
                <div className=''>
                    <h4 className='text-[14px] font-thin text-[#333333]'><Link to='/'>Home</Link> / Privacy Policy</h4>
                    <h1 className='text-[24px] lg:text-[28px] font-semibold mt-5 text-[#333333] '>Privacy Policy</h1>

                    <div className="jumbotron"  style={styles.container}>
                        <p><strong>Last updated:</strong> August 14, 2024</p>

                        <p>This Privacy Policy explains Our policies and procedures regarding the collection, use, and disclosure of Your information when You use the Service. It also explains Your privacy rights and how the law protects You.</p>

                        <p>By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>

                        <h2>Interpretation and Definitions</h2>

                        <h3>Interpretation</h3>
                        <p>Words with initial capital letters have meanings defined under the following conditions. These definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

                        <h3>Definitions</h3>
                        <p>For the purposes of this Privacy Policy:</p>
                        <ul>
                            <li><strong>Account:</strong> A unique account created for You to access our Service or parts of our Service.</li>
                            <li><strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with a party.</li>
                            <li><strong>Application:</strong> Refers to Meerana App, the software program provided by the Company.</li>
                            <li><strong>Company:</strong> (referred to as "the Company", "We", "Us" or "Our" in this Agreement) refers to Meerana Technologies, #1704, Blue Bay Tower, Business Bay, Dubai, UAE.</li>
                            <li><strong>Country:</strong> Refers to the United Arab Emirates.</li>
                            <li><strong>Device:</strong> Any device that can access the Service such as a computer, cellphone, or digital tablet.</li>
                            <li><strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.</li>
                            <li><strong>Service:</strong> Refers to the Application.</li>
                            <li><strong>Service Provider:</strong> Any natural or legal person who processes the data on behalf of the Company.</li>
                            <li><strong>Usage Data:</strong> Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
                            <li><strong>You:</strong> The individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                        </ul>

                        <h2>Collecting and Using Your Personal Data</h2>

                        <h3>Types of Data Collected</h3>

                        <h4>Personal Data</h4>
                        <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. This includes:</p>
                        <ul>
                            <li>Email address</li>
                            <li>First name and last name</li>
                            <li>Phone number</li>
                            <li>Profile photo</li>
                            <li>Location information (accurate)</li>
                        </ul>

                        <h4>Usage Data</h4>
                        <p>Usage Data is collected automatically when using the Service. This may include:</p>
                        <ul>
                            <li>Your Device's Internet Protocol address (e.g., IP address)</li>
                            <li>Browser type and version</li>
                            <li>Pages of our Service that You visit</li>
                            <li>Time and date of Your visit</li>
                            <li>Time spent on those pages</li>
                            <li>Unique device identifiers and other diagnostic data</li>
                        </ul>
                        <p>When You access the Service by or through a mobile device, We may collect additional information, including:</p>
                        <ul>
                            <li>Mobile device type</li>
                            <li>Mobile device unique ID</li>
                            <li>IP address of Your mobile device</li>
                            <li>Mobile operating system</li>
                            <li>Type of mobile Internet browser used</li>
                            <li>Unique device identifiers and other diagnostic data</li>
                        </ul>

                        <h4>Information Collected While Using the Application</h4>
                        <p>With Your prior permission, We may collect and use the following information while using Our Application:</p>
                        <ul>
                            <li><strong>Location Information:</strong> To provide accurate location-based services.</li>
                            <li><strong>Camera and Photo Library:</strong> To upload and manage photos, including profile photos.</li>
                            <li><strong>Voice Access:</strong> We have integrated voice access in our app to enhance user experience.</li>
                        </ul>
                        <p>You can enable or disable access to this information through Your Device settings at any time.</p>

                        <h4>Explicit Consent</h4>
                        <p>We obtain Your explicit consent before collecting or accessing sensitive data such as location, camera, and microphone access. You have the right to withdraw Your consent at any time by adjusting Your device settings or contacting Us directly.</p>

                        <h3>Use of Your Personal Data</h3>

                        <p>The Company may use Personal Data for the following purposes:</p>
                        <ul>
                            <li><strong>To provide and maintain our Service:</strong> Including monitoring usage of our Service.</li>
                            <li><strong>To manage Your Account:</strong> To manage Your registration and access to different functionalities of the Service.</li>
                            <li><strong>For the performance of a contract:</strong> To develop and manage contracts related to the products, services, or items You have purchased or requested.</li>
                            <li><strong>To contact You:</strong> To communicate by email, phone, SMS, or push notifications regarding updates or other important information about the Service.</li>
                            <li><strong>To provide You with news and offers:</strong> Regarding goods, services, or events similar to those You have purchased or inquired about.</li>
                            <li><strong>To manage Your requests:</strong> To handle and manage Your requests.</li>
                            <li><strong>For business transfers:</strong> To evaluate or conduct mergers, sales, restructurings, or other transfers of assets.</li>
                            <li><strong>For other purposes:</strong> To analyze data, identify trends, evaluate promotional campaigns, and improve our Service.</li>
                        </ul>

                        <h2>Data Retention</h2>
                        <p>We retain Your Personal Data only as long as necessary to fulfill the purposes outlined in this Privacy Policy. Usage Data is typically retained for a shorter period, unless required to ensure the security or functionality of our Service. You may request deletion of Your data by contacting Us, and We will comply unless We are legally required to retain the information.</p>

                        <h2>Data Sharing</h2>
                        <p>We do not share any of Your personal data, including profile photo, name, email ID, and mobile number, with third parties. Your data is solely used to provide and improve the Service.</p>

                        <h2>Third-Party Services</h2>
                        <p>We may use third-party services and analytics tools to help Us understand how our Service is used. These third-party service providers may collect information sent by Your device, such as cookies or Your IP address. We ensure that all third-party services comply with this Privacy Policy and applicable data protection laws.</p>

                        <h2>User Rights</h2>
                        <p>You have the right to:</p>
                        <ul>
                            <li>Access Your Personal Data.</li>
                            <li>Request correction of any inaccurate or incomplete data.</li>
                            <li>Request deletion of Your Personal Data.</li>
                            <li>Object to or restrict processing of Your Personal Data.</li>
                            <li>Withdraw consent at any time where We rely on Your consent to process Your data.</li>
                        </ul>
                        <p>To exercise any of these rights, please contact Us at <a href="mailto:info@meerana.ae">info@meerana.ae</a>.</p>

                        <h2>International Users</h2>
                        <p>Our Service is available globally, and We comply with international data protection laws, including the General Data Protection Regulation (GDPR) for users in the European Union. We ensure that Your Personal Data is processed in accordance with these laws, regardless of where You are located.</p>

                        <h2>Compliance with Google Play Policies</h2>
                        <ul>
                            <li><strong>Deceptive Behavior Policy:</strong> We do not engage in any deceptive practices. All features of the Application are clearly disclosed, and all data collection is transparent.</li>
                            <li><strong>Data Safety Section:</strong> We accurately represent how We collect, use, and share personal and sensitive data. Our Data Safety form is up-to-date and reflects our current practices.</li>
                            <li><strong>Broken Functionality Policy:</strong> We ensure that all features of the Application function as intended and provide a seamless user experience. Any issues will be promptly addressed and fixed.</li>
                        </ul>

                        <h2>Children's Privacy</h2>
                        <p>Our Service is not directed at anyone under the age of 13, and We do not knowingly collect personal data from children under 13. If We learn that We have collected data from a child under 13 without verification of parental consent, We will take steps to delete that information.</p>

                        <h2>Changes to this Privacy Policy</h2>
                        <p>We may update this Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date. You are advised to review this Privacy Policy periodically.</p>

                        <h2>Contact Us</h2>
                        <p>If You have any questions about this Privacy Policy, You can contact Us:</p>
                        <ul>
                            <li>By email: <a href="mailto:info@meerana.ae">info@meerana.ae</a></li>
                        </ul>

                    </div>

                </div>
            </section>
        </div>
    )
}

export default Privacy
